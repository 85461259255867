// Import CSS Loader
import "./src/loader.css"

// Import JS
require("jquery/dist/jquery.min.js")
require("popper.js/dist/popper.min")
require("bootstrap/dist/js/bootstrap.min.js")

export const onInitialClientRender = () => {
  setTimeout(function () {
    document.body.classList.add('loaded');
    window.scrollTo(0, 0);
  }, 3000)
}
